body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.rbc-btn-group{
  display: none !important;
}

.rbc-timeslot-group{
  border-bottom: none !important;
}

.rbc-time-slot {
  border-top: none !important;
}

.kachel {
  border: 1px solid #1565c0;
  border-radius: 5px;
  width: 100%;
  height: 110px;
}

